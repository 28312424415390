import React from "react";
import { ROUTE as ackRoute } from "../../pages/Acknowledgements/Acknowledgements";
import { ROUTE as aboutRoute } from "../../pages/About/About";
import { ROUTE as supernovaInTheEastRoute } from "../../pages/SupernovaInTheEast/SupernovaInTheEast";
import { ROUTE as hundredYearsWarRoute } from "../../pages/HundredYearsWar/HundredYearsWar";
import { ROUTE as homeRoute } from "../../pages/Home";
import { ROUTE as punicWarsRoute } from "../../pages/PunicWars/PunicWars";
import { ROUTE as quizRoute } from "../../pages/Quiz/Quiz";
import { Link } from "react-router-dom";
import { getDynamicLink } from "../../data/routeUtils";
import { MapSide } from "../../data/dataMaps";
import "./Footer.scss";
import { Logo } from "../Logo/Logo";
import { Image } from "../Images/Image";

interface Props {
  mapSide?: MapSide;
}

export const Footer: React.FunctionComponent<Props> = ({ mapSide }) => (
  <footer className={mapSide}>
    <div className="footer-border" />
    <div className="footer-wrapper">
      <section>
        <article>
          <div className="grid">
            <div className="maps">
              <strong>Maps</strong>
              <ul>
                <li>
                  <Link to={getDynamicLink(punicWarsRoute, { year: "264bc" })}>
                    The Punic Wars
                  </Link>
                </li>
                <li>
                  <Link
                    to={getDynamicLink(supernovaInTheEastRoute, {
                      year: "1870",
                    })}
                  >
                    Imperial Japan 1870 - 1945
                  </Link>
                </li>
                <li>
                  <Link
                    to={getDynamicLink(hundredYearsWarRoute, { year: "1337" })}
                  >
                    Hundred Years' War
                  </Link>
                </li>
              </ul>
            </div>

            <div className="pages">
              <strong>Pages</strong>
              <ul>
                <li>
                  <Link to={ackRoute}>Acknowledgements</Link>
                </li>
                <li>
                  <Link to={aboutRoute}>About</Link>
                </li>
              </ul>
            </div>

            <div className="support">
              <strong>quiz</strong>
              <ul>
                <li className="hide">
                  <a
                    href="https://www.paypal.com/donate?hosted_button_id=S9KWSDFWJBE92"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paypal
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://www.patreon.com/interactive_history"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Patreon
                  </a>
                </li> */}
                <li>
                  <Link to={quizRoute}>Ancient times</Link>
                </li>
              </ul>
            </div>

            <div className="social">
              <strong>Social media</strong>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/InteractiveHis1"
                    target="_blank"
                    rel="noreferrer"
                    className="twitter-icon"
                  >
                    <Image
                      ratio={248 / 204}
                      src="/logos/twitter.svg"
                      alt="twitter icon"
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://discord.gg/FRPYJGaZF5"
                    target="_blank"
                    rel="noreferrer"
                    className="discord-icon"
                  >
                    <Image
                      ratio={245 / 240}
                      src="/logos/discord.svg"
                      alt="Discord icon"
                    />
                  </a>
                </li>

                <li className="reddit-link">
                  <a
                    href="https://reddit.com/u/interactive-history"
                    target="_blank"
                    rel="noreferrer"
                    className="reddit-icon"
                  >
                    <Image
                      ratio={1}
                      src="/logos/reddit.svg"
                      alt="Reddit icon"
                    />
                  </a>
                </li>
              </ul>
            </div>

            <div className="contact">
              <strong>Contact</strong>
              <ul>
                <li>
                  <a href="mailto:average.productions.ab@gmail.com">
                    average.productions.ab@gmail.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://average.productions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    average.productions
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-logo">
              <Link to={homeRoute}>
                <Logo />
                <span>&copy; 2022 &#126; Interactive History</span>
              </Link>
            </div>

            <p className="consent">
              I use Google Analytics for some rudimentary analysis of the
              traffic to this site. That's it. I don't track individual users
              and never will. In order to improve this site, in the future, I
              might extend Google Analytics or add some other statistics
              framework but, again, never to track individual users.
            </p>
          </div>
        </article>
      </section>
    </div>
  </footer>
);
